body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  // font-family: "Times New Roman", Times, serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ant-typography, .ant-layout, .ant-radio-wrapper, .ant-btn{
  // font-family: "Times New Roman", Times, serif !important;

}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }

.maxHeight{
  height: 100%;
}
.appPadding{
  padding: 0 10vw;
}
.scrollableDiv{
  overflow: auto;
}

.buttonBaseStyle{
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}
.buttonstyle{
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  background: #b5ea43;
  color: black !important;
  font-size: 14px;
  font-weight: bold;
  &:hover{
    background: #b5ea43d0;
  }
}

.introWrap{
  .cardStyle{
    display: block !important;
    height: 100%;
  }
}

.mainContainer{
  height: 100vh;
  width: 100vw;
  font-size: 12px !important;
  background-color: #fff;
  .navbar{
    height: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #000213;
    .logowrap{
      height: 75%;
      img{
        height: 100%;
        width: auto;
      }
    }
    .navbar_right{
      font-size: 25px;
      font-weight: 400;
      letter-spacing: 1.5px;
      span{
        color: #b5ea43;
      }
    }
  }
  .contentContainer{
    height: calc(100% - 10vh);
    overflow: hidden;
    display: flex;
    .cardStyle{
      width: 100%;
      border-radius: 0;
      background-color: white;
      align-items: center;
    }
    .section{
      width: 100% !important;
      height: 100%;
      border-radius: 0;
      // @extend .appPadding;
    }
  }
  
}
.sectionHeaderMobile{
  display: none;
}

// .sectionContainer{
//   width: 100%;
//   height: 100%;
//   background: white;
//   padding: 1em;
//   overflow: auto;
// }


.categorySummary{
  padding: 0;
}

.resultsLogowrap{
  margin: 2em 0;
  width: 100%;
  text-align: center;
  img{
    width: 150px;
    height: auto;
  }
  .resultsLogowrap_text{
    .subheader{
      margin: 0;
      padding-bottom: 1em;
      border-bottom: 1px solid #eee;
    }
  }
}


    .sitelink{
      color: #911863;
      &:hover{
        color: #ff008a;
      }
    }

@media only screen and (max-width: 1400px) {
  .appPadding{
    padding: 0 8vw;
  }
}
@media only screen and (max-width: 1200px) {
  .appPadding{
    padding: 0 5vw;
  }
}
@media only screen and (max-width: 1000px) {
  .mainContainer .contentContainer{
    .section{
      .sectionSider{
        display: none;
      }
    }
  }
  .sectionHeaderMobile{
    display: block !important;
  }
  .buttonBaseStyle{
    padding: 10px 20px;
    font-size: 12px;
  }
}
@media only screen and (max-width: 800px) {
  .appPadding{
    padding: 0 2vw;
  }
  .mainContainer{
    .navbar{
      .logowrap{
        height: 70%;
      }
      .navbar_right{
        font-size: 14px;
        letter-spacing: 1px;
      }
    }
  }
  .buttonstyle{
    padding: 10px 15px;
    font-size: 12px;
  }
}